import { createRenderer } from 'fela-native';
import React from 'react';
import { RendererProvider } from 'react-fela';
import { AppRegistry, Platform } from 'react-native';
import { enableScreens } from 'react-native-screens';
import { Tracer } from '@oolio-group/tracer-client';
import { App as AppType } from '@oolio-group/domain';
import { getVersion } from 'react-native-device-info';
require('react-native-gesture-handler');

enableScreens();

// eslint-disable-next-line @typescript-eslint/no-var-requires
const App = require('./App').default;
const renderer = createRenderer();

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const wrappedApp = () => (
  <RendererProvider renderer={renderer}>
    <App />
  </RendererProvider>
);

export { wrappedApp as App };

AppRegistry.registerComponent('OolioCDS', () => wrappedApp);

if (Platform.OS === 'web') {
  AppRegistry.runApplication('OolioCDS', {
    rootTag: document.getElementById('root'),
  });
}

const getTracingLocalStorageData = async () => {
  return {};
};

Tracer.getInstance(
  {
    devMode: __DEV__, // dev mode
    platform: Platform.OS,
    app: AppType.CUSTOMER_DISPLAY_APP,
    environment: process.env['REACT_APP_LOGGING_ENV'] || process.env.NODE_ENV, // local, test-in, staging-x, prod-green
    dsn: 'https://280699ec880c4959b534df9e059a5fe4@o314552.ingest.sentry.io/4504075322654720', // metric data go to pos-app or cds-app,...
    version: getVersion(),
  },
  getTracingLocalStorageData,
);
if (!__DEV__) {
  Tracer.wrap(App);
}
